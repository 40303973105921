<template>
  <div>
    <b-card-actions
        ref="refreshCard"
        action-refresh
        action-create
        disable-loading-on-refresh
        @refresh="getItems"
        @create="hRedirect('blockedtime-new')"
        :loaded.sync="loaded"
    >
      <table-header :filter="filter" :per-page.sync="perPage"/>
        <b-overlay :show="!loaded" rounded="sm">
          <b-table
              ref="refBookingtimesListTable"
              class="position-relative"
              responsive
              primary-key="id"
              :items="items"
              :per-page="perPage"
              :current-page="currentPage"
              :fields="tableColumns"
              :sort-by.sync="sortBy"
              :filter="filter.input"
              @filtered="onFiltered"
              show-empty
              :empty-text="$t('No matching records found')"
              :empty-filtered-text="$t('No matching records found')"
          >
            <template #head()="data">
              {{ $t(data.label) }}
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-button
                  :to="{
              name: 'blockedtime-edit',
              params:{
                id: data.item.id },
              }"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  type="submit"
                  size="sm"
              >
                {{ $t('Edit') }}
              </b-button>
              <b-button v-on:click="deleteItem(data.item.id)" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        type="submit" size="sm"
              >
                {{ $t('Delete') }}
              </b-button>
            </template>
          </b-table>
        </b-overlay>
      <table-footer
          :current-page.sync="currentPage"
          :per-page="perPage"
          :length="totalRows"
      />

    </b-card-actions>

    <b-card no-body class="mb-0" v-if="isCalendarLinked">
      <h4 class="h4 text-center py-3 px-2">
        {{ $t('You are having a linked google calendar. Please block your times in the google calendar.') }}
      </h4>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions'
import TableHeader from '@/views/components/TableHeader'
import TableFooter from '@/views/components/TableFooter'
import moment from 'moment'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    BCardActions,
    TableHeader,
    TableFooter,
    vSelect,
    moment
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loaded: false,
      isCalendarLinked: false,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: {
        input: null
      },
      tableColumns: [
        {
          key: 'start_date',
          sortable: true,
          label: 'Start date',
          formatter: (value) => {
            return this.formateDateIfGerman(value)
          }
        },
        {
          key: 'start_time',
          sortable: true,
          label: this.$i18n.t('Start time')
        },
        {
          key: 'end_date',
          sortable: true,
          label: 'End date',
          formatter: (value) => {
            return this.formateDateIfGerman(value)
          }
        },
        {
          key: 'end_time',
          sortable: true,
          label: this.$i18n.t('End time')
        },
        {
          key: 'actions',
          label: 'Actions',
          class: 'table-action-buttons'
        }
      ],
      items: [],
    }
  },
  computed: {},
  mounted() {
    this.getItems()
  },
  methods: {
    translate(text) {
      return this.$t(text)
    },
    getItems() {
      this.items = []
      this.loaded = false

      this.helperGetItems(`/blockedtimes`)
    },
    deleteItem(id) {
      this.helperDeleteItem(
          `/blockedtimes/${id}`,
      )
      this.$store.dispatch('GET_USER_ONBOARDING')
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">

</style>
